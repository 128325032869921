import { makeStyles } from '@material-ui/core/styles';
import { borderRadius } from '../../../../styles/themes/vad/borderRadius';
import { pxToRem } from '../../../../styles/themes/vad/constants';
import { fontSize } from '../../../../styles/themes/vad/font';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'fixed',
    top: pxToRem(0),
    bottom: 'auto',
    ...theme.mixins.right(pxToRem(0)),
    borderRadius: borderRadius.b4Rem,
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    zIndex: 11000,
    margin: 0,
    [theme.breakpoints.up('lg')]: {
      width: '38.5%',
      height: 'auto',
      maxWidth: pxToRem(392),
      top: 'auto',
      bottom: pxToRem(16),
      ...theme.mixins.right(pxToRem(16)),
      background: theme.mixins.White(900),
      boxShadow: theme.shadows[8],
    },
    '& .close-btn': {
      color: theme.mixins.Black(),
      position: 'absolute',
      top: theme.spacing(3),
      ...theme.mixins.right(theme.spacing(3)),
      zIndex: '10',
      width: pxToRem(40),
      height: pxToRem(40),
      background: theme.mixins.White(900),
      borderRadius: borderRadius.circle,
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: 0,
      [theme.breakpoints.up('lg')]: {
        top: theme.spacing(4),
        ...theme.mixins.right(theme.spacing(4)),
        width: pxToRem(48),
        height: pxToRem(48),
      },

      '&:hover': {
        borderColor: 'transparent',
        background: theme.mixins.White(),
      },

      '& svg': {
        height: theme.spacing(2),
        width: theme.spacing(2),
        [theme.breakpoints.up('lg')]: {
          height: theme.spacing(4),
          width: theme.spacing(4),
        },
      },

      '&.small': {
        top: pxToRem(24),
        ...theme.mixins.right(pxToRem(24)),
        width: pxToRem(40),
        height: pxToRem(40),
        [theme.breakpoints.up('lg')]: {
          top: pxToRem(16),
          ...theme.mixins.right(pxToRem(16)),
          width: pxToRem(48),
          height: pxToRem(48),
        },

        '& svg': {
          height: pxToRem(17.2),
          width: pxToRem(17.2),
          [theme.breakpoints.up('lg')]: {
            height: pxToRem(20.6),
            width: pxToRem(20.6),
          },
        },
      },
    },
    '& .slick-dots': {
      position: 'relative',
      bottom: 0,
      fontSize: fontSize.s0Rem,
      lineHeight: fontSize.s0Rem,
      marginTop: pxToRem(-8),
      padding: `0 ${pxToRem(24)} ${pxToRem(32)}`,
      textAlign: theme.mixins.textLeft(),
      ...theme.mixins.direction(),
      [theme.breakpoints.up('lg')]: {
        marginTop: pxToRem(0),
        padding: `0 ${pxToRem(32)} ${pxToRem(48)}`,
      },
      '& li': {
        ...theme.mixins.marginRight(pxToRem(4)),
        ...theme.mixins.marginLeft(pxToRem(0)),
        height: 'auto',
        width: 'auto',
        '& button': {
          width: pxToRem(24),
          height: pxToRem(4),
          backgroundColor: theme.palette.common.DisabledGrey,
          borderRadius: borderRadius.b999px,
          padding: 0,
          '&:before': {
            display: 'none',
          },
        },
        '&.slick-active': {
          '& button': {
            backgroundColor: theme.palette.common.ActiveGray,
          },
        },
        '&:last-child': {
          ...theme.mixins.marginRight(pxToRem(0)),
          ...theme.mixins.marginLeft(pxToRem(0)),
        },
      },
    },
    '& .slick-slide': {
      transform: 'translateZ(0)',
      float: 'left',
    },
  },
  bgLayer: {
    display: 'block',
    position: 'absolute',
    top: 0,
    left: 0,
    background: theme.mixins.Black(400),
    width: '100%',
    height: '100%',
    zIndex: 0,
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  cardWrapper: {
    display: 'flex',
    flexDirection: 'column',
    transition: 'box-shadow .3s, background-color .3s',
    '&:hover': {
      backgroundColor: theme.mixins.White(),
      '& h3': {
        color: theme.palette.common.SeaPort,
      },
      '& :not(.img-to-animate-wrapper) img, & .img-to-animate-wrapper': {
        transform: 'scale(1.1, 1.1)',
      },
      '& .icon img': {
        transform: 'none',
      },
      '& .MuiChip-root': {
        backgroundColor: theme.palette.background.default,
      },
    },
    '& .MuiChip-root': {
      cursor: 'pointer',
      ...theme.mixins.marginRight(theme.spacing(2)),
      backgroundColor: theme.palette.background.default,
      '& .MuiTypography-root': {
        color: theme.palette.common.SeaPort,
      },
    },
    '&.listing-card': {
      maxWidth: pxToRem(327),
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 'calc(100% - 48px)',
      height: 'auto',
      backgroundColor: theme.mixins.White(),
      zIndex: 0,
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.up('lg')]: {
        height: '100%',
        transform: 'none',
        maxWidth: 'none',
        position: 'static',
        width: '100%',
      },
      '&:hover': {
        transition: 'box-shadow .3s',
        boxShadow: theme.shadows[1],
        '& .card-description': {
          boxShadow: 'none',
          backgroundColor: 'none',
        },
      },
      '& .card-description': {
        backgroundColor: 'transparent',
        boxShadow: theme.shadows[0],
        flex: 'auto',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        borderBottom: '0',
        '& h3': {
          minHeight: 'auto',
        },
      },
      '&.horizontal-card': {
        flexDirection: 'row',
        '& .card-description': {
          padding: pxToRem(24),
          ...theme.mixins.paddingLeft(pxToRem(20)),
          [theme.breakpoints.up('lg')]: {
            flex: '0 0 75%',
            maxWidth: '75%',
            margin: 0,
            padding: `${pxToRem(40)} ${pxToRem(32)} ${pxToRem(32)} ${pxToRem(32)}`,
          },
          '& h3': {
            marginBottom: theme.spacing(1),
          },
          '& p': {
            color: theme.palette.text.darkGrey,
            marginBottom: theme.spacing(2),
            ...theme.palette.common.ellipsis(2),
            maxHeight:
              theme.typography.body2.lineHeight.replace('rem', '') * 2 + 'rem',
            [theme.breakpoints.up('lg')]: {
              ...theme.palette.common.ellipsis(3),
              maxHeight:
                theme.typography.body2.lineHeight.replace('rem', '') * 3 +
                'rem',
            },
          },
        },
        '& .img-wrapper': {
          flex: '0 0 25%',
          overflow: 'hidden',
          margin: `${theme.spacing(4)} ${theme.spacing(3)} ${theme.spacing(3)}`,
          ...theme.mixins.marginRight(0),
          [theme.breakpoints.up('lg')]: {
            margin: 0,
          },
          '& img': {
            display: 'block',
            height: 'auto',
          },
        },
      },
    },
  },
  imageWrapper: {
    position: 'relative',
    overflow: 'hidden',
    height: pxToRem(218),
    [theme.breakpoints.up('lg')]: {
      height: pxToRem(261),
    },
    '& :not(.img-to-animate-wrapper) img, & .img-to-animate-wrapper': {
      transition: 'transform 1s ease',
      willChange: 'transform',
    },
    '&.collage': {
      marginBottom: theme.spacing(4),
    },
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: theme.mixins.textLeft('left'),
    '&.card-description': {
      padding: pxToRem(24),
      [theme.breakpoints.up('lg')]: {
        padding: `${pxToRem(40)} ${pxToRem(32)} ${pxToRem(32)} ${pxToRem(32)}`,
      },
    },
    '& .MuiTypography-cardTitle1': {
      marginBottom: theme.spacing(1),
    },
    '& .message-description': {
      color: theme.mixins.Grey(),
      marginTop: pxToRem(16),
      ...theme.palette.common.ellipsis(4),
      [theme.breakpoints.up('lg')]: {
        ...theme.palette.common.ellipsis(3),
        textOverflow: 'ellipsis',
        marginTop: pxToRem(8),
      },
      '& p': {
        margin: 0,
        fontSize: 'inherit',
        fontFamily: 'inherit',
        lineHeight: 'inherit',
        letterSpacing: 'inherit',
        ...theme.palette.common.ellipsis(4),
        [theme.breakpoints.up('lg')]: {
          ...theme.palette.common.ellipsis(3),
          textOverflow: 'ellipsis',
          maxHeight: 'calc(1rem * 2.1 * 6)',
        },
      },
    },
    '& .primaryCTA': {
      marginTop: pxToRem(16),
      [theme.breakpoints.up('lg')]: {
        marginTop: pxToRem(24),
      },
      '& .MuiButton-iconSizeSmall': {
        padding: pxToRem(10),
      },
      '&.btnWithBorder': {
        paddingTop: pxToRem(8),
        borderTopWidth: '1px',
        borderTopColor: theme.palette.common.MysticGrey,
        borderTopStyle: 'solid',
        [theme.breakpoints.up('lg')]: {
          paddingTop: pxToRem(16),
        },
      },
    },
  },
}));

export default useStyles;
