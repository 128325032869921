import { canUseDOM } from './canUseDOM';
import {
  INSIDE_CHINA_COUNTRY_CODE,
  LANGUAGE_CODE,
  DOMAIN_SUFFIX,
} from '../../constants';
import { get } from 'lodash';

export const cookie = {
  writeSessionCookie: function (cname, cvalue) {
    canUseDOM &&
      (document.cookie = cname + '=' + cvalue + '; path=/' + '; secure;');
  },
  write: function (cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    var expires = 'expires=' + d.toUTCString();
    canUseDOM &&
      (document.cookie =
        cname + '=' + cvalue + '; ' + expires + '; path=/' + '; secure;');
  },
  read: function (name) {
    if (canUseDOM && document.cookie.indexOf(name) > -1) {
      return document.cookie
        .split(name)[1]
        .split('; ')[0]
        .substr(1);
    } else {
      return '';
    }
  },
  delete: function (cname) {
    var d = new Date();
    d.setTime(d.getTime() - 1000);
    var expires = 'expires=' + d.toUTCString();
    canUseDOM && (document.cookie = cname + '=; ' + expires);
  },
};

export default cookie;

export const cookiesList = {
  _Country: '_country',
};

//inside china OR browsing chinese site
export const isInsideChina = sitecoreContext => {
  const countryCode =
    cookie.read(cookiesList._Country) &&
    cookie.read(cookiesList._Country).toLocaleLowerCase();
  const language = get(sitecoreContext, 'language', LANGUAGE_CODE.DEFAULT);
  return (
    countryCode === INSIDE_CHINA_COUNTRY_CODE ||
    isLanguageEqual(language, LANGUAGE_CODE.CHINESE)
  );
};

// is china domain e.g. visitabudhabi.cn
export const isDomainCN = () => {
  const locationHostArr = canUseDOM && window.location.hostname.split('.');
  const domainCode =
    locationHostArr && locationHostArr[locationHostArr.length - 1];
  return domainCode && domainCode.toLowerCase() === 'cn';
};

//inside china AND not browsing site in chinese
export const evalConditionForInsideChina = language => {
  const countryCode =
    cookie.read(cookiesList._Country) &&
    cookie.read(cookiesList._Country).toLocaleLowerCase();
  return (
    countryCode === INSIDE_CHINA_COUNTRY_CODE &&
    !isLanguageEqual(language, LANGUAGE_CODE.CHINESE)
  );
};

//outside china AND not browsing site in chinese
export const evalConditionForSocialShare = language => {
  const countryCode =
    cookie.read(cookiesList._Country) &&
    cookie.read(cookiesList._Country).toLocaleLowerCase();
  return !(
    countryCode !== INSIDE_CHINA_COUNTRY_CODE &&
    !isLanguageEqual(language, LANGUAGE_CODE.CHINESE)
  );
};

export const evalConditionForIcpLicense = language => {
  if (isLanguageEqual(language, LANGUAGE_CODE.CHINESE)) {
    return true;
  } else {
    const domainName = canUseDOM && window.location.hostname;
    const domainSuffix =
      domainName && domainName.substr(domainName.lastIndexOf('.'));
    return domainSuffix === DOMAIN_SUFFIX.CHINA;
  }
};

export const isLanguageEqual = (selectedLanguage, checkLanguage) => {
  const selectedLanguageLowerCase =
    selectedLanguage && selectedLanguage.toLowerCase();
  const checkLanguageLowerCase = checkLanguage && checkLanguage.toLowerCase();
  return selectedLanguageLowerCase === checkLanguageLowerCase;
};
