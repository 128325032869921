import React, { useEffect, useState, useRef } from 'react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import useStyles from './style';
import { COMPONENT_NAME } from './locators';
import Box from '../../../components/molecules/Box';
import clsx from 'clsx';
import Picture from '../../../components/atoms/Picture';
import Typography from '../../../components/atoms/Typography';
import { get } from 'lodash';
import Button from '../../../components/atoms/Button';
import DctSvgIcon from '../../../components/atoms/Svg';
import cookie from '../../../utils/cookie';
import {
  HOME_PAGE_ROUTE_NAME,
  MARKETING_MESSAGE_INACTIVE_TILL,
  MARKETING_MESSAGE_HIDDEN,
} from '../../../../constants';
import {
  addDays,
  dateParseToMilliseconds,
} from '../../../utils/dateTimeFunctions/dateTimeFunctions';
import aspectRatios from '../../../utils/AspectRatios';
import withErrorBoundary from '../../../utils/HOCs/withErrorBoundary';
import RichText from '../../../components/atoms/RichText';
import { canUseDOM } from '../../../utils/canUseDOM';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import '../../../../styles/themes/slick-themes.css';
import { settings } from './config';

const MarketingMessage = props => {
  const {
    inactiveDaysCount,
    loadTime,
    expiryDate,
    isActive,
    isGlobal,
    carouselItems,
  } = get(props, 'fields', {});
  const [hidden, setHidden] = useState(false);
  const marketingMessageParent = useRef(null),
    mmSlider = useRef(null);
  const [
    carouselItemsWithoutCurrentLink,
    setCarouselItemsWithoutCurrentLink,
  ] = useState([]);
  const classes = useStyles();
  const pageName = get(props, 'sitecoreContext.route.name');
  const isHomePage =
    pageName && pageName.toLowerCase() === HOME_PAGE_ROUTE_NAME.toLowerCase();

  const onCloseMarketingMessage = () => {
    setHidden(false);
    cookie.write(
      MARKETING_MESSAGE_INACTIVE_TILL,
      addDays(inactiveDaysCount && inactiveDaysCount.value),
      inactiveDaysCount && inactiveDaysCount.value
    );
    cookie.writeSessionCookie(MARKETING_MESSAGE_HIDDEN, true);
  };

  // const escFunction = useCallback((event) => {
  //   if (event.keyCode === 27) {
  //     onCloseMarketingMessage();
  //   }
  // }, []);

  useEffect(() => {
    const marketingMessageCookie = canUseDOM && cookie.read(MARKETING_MESSAGE_HIDDEN);
    const inactiveDateTime = cookie.read(MARKETING_MESSAGE_INACTIVE_TILL);
    const expiryMilliseconds = dateParseToMilliseconds(
      expiryDate && expiryDate.value
    );
    const todayTimeMilliseconds = dateParseToMilliseconds(new Date());
    let isUserOnLinkedPage = false;
    // document.addEventListener('keydown', escFunction, false);

    // Sort out slides which dont contain link the the same page user is at.
    let totalItems = [];
    if (canUseDOM) {
      isUserOnLinkedPage = true;
      carouselItems &&
        carouselItems.forEach(item => {
          if (
            get(item.fields.link, 'value.href', '') !== window.location.pathname
          ) {
            totalItems.push(item);
            isUserOnLinkedPage = false;
          }
        });
      setCarouselItemsWithoutCurrentLink(totalItems);
    }

    if (
      isActive &&
      isActive.value &&
      !inactiveDateTime &&
      todayTimeMilliseconds < expiryMilliseconds &&
      !marketingMessageCookie &&
      !isUserOnLinkedPage
    ) {

      // these timeouts will load the marketing message but show it after
      // timeRequiredToAdjust time + after moving it once back and fourth
      // this will ensure that cards doesn't jerk or jumps on first load
      let timeRequiredToAdjust = 5000;
      let loadTimeMilliseconds = loadTime && loadTime.value * 1000;
      if (loadTimeMilliseconds > timeRequiredToAdjust) {
        loadTimeMilliseconds = loadTimeMilliseconds - timeRequiredToAdjust;
      } else {
        loadTimeMilliseconds = 0;
      }
      setTimeout(() => {
        setHidden(true);
        setTimeout(() => {
          mmSlider.current.slickGoTo(1);
          setTimeout(() => {
            mmSlider.current.slickGoTo(0);
          }, timeRequiredToAdjust / 3);
        }, timeRequiredToAdjust / 3);
        setTimeout(() => {
          marketingMessageParent.current.style.opacity = 1;
          marketingMessageParent.current.style.pointerEvents = 'auto';
        }, timeRequiredToAdjust);
      }, loadTimeMilliseconds);
    }

    // return () => {
    //   document.removeEventListener('keydown', escFunction, false);
    // };
  }, []);

  if (((isGlobal && isGlobal.value) || isHomePage) && hidden) {
    return (
      <div className={classes.root} data-locators={COMPONENT_NAME} ref={marketingMessageParent} style={{
        opacity: 0,
        pointerEvents: 'none',
      }}>
        <div
          className={classes.bgLayer}
          onClick={() => onCloseMarketingMessage()}
        ></div>
        <Box className={clsx(classes.cardWrapper, 'listing-card')}>
          <Button
            iconOnly
            color="inherit"
            size="small"
            className={clsx('close-btn', 'small')}
            onClick={() => onCloseMarketingMessage()}
            animateSettings={{
              willAnimate: false,
            }}
          >
            <DctSvgIcon name="CloseIcon" />
          </Button>
          {carouselItemsWithoutCurrentLink && (
            <Slider {...settings} ref={mmSlider}>
              {carouselItemsWithoutCurrentLink.map(item => {
                const { title, description, image, mobileImage, link } = get(
                  item,
                  'fields',
                  ''
                );
                return (
                  <div key={item}>
                    <Box className={clsx(classes.imageWrapper, 'fallback-img')}>
                      <Picture
                        media={{
                          image: image,
                          mobileImage: mobileImage,
                          aspectRatio: aspectRatios['3x2'],
                          disableLazyLoad: true,
                        }}
                        alternateAltText={title && title.value}
                        isParalax={false}
                      />
                    </Box>
                    <Box
                      className={clsx(classes.cardContent, 'card-description')}
                    >
                      <div>
                        {title && title !== '' && (
                          <Typography variant="h51" component="h3"
                            animateSettings={{
                              willAnimate: false,
                            }}>
                            {title.value}
                          </Typography>
                        )}
                        {description && description !== '' && (
                          <Typography
                            variant="body4"
                            component="div"
                            className="message-description"
                          >
                            <RichText innerHTML={description} />
                          </Typography>
                        )}
                        {get(link, 'value.href', '') !== '' && (
                          <Button
                            size="small"
                            component="span"
                            buttonType="primary"
                            field={link}
                            hasBorder={true}
                            className="primaryCTA"
                            animateSettings={{
                              willAnimate: false,
                            }}
                          />
                        )}
                      </div>
                    </Box>
                  </div>
                );
              })}
            </Slider>
          )}
        </Box>
      </div>
    );
  } else {
    return null;
  }
};

export default withErrorBoundary(withSitecoreContext()(MarketingMessage));
