import { ARABIC_LANG_LAYOUT, ARABIC_LANG_API_PAYLOAD } from '../../../constants/index';
import moment from 'moment';

export const getProcessedEventDate = (date, language = 'en') => {
  let lang;
  if (language === ARABIC_LANG_LAYOUT) {
    lang = ARABIC_LANG_API_PAYLOAD;
  } else {
    lang = language;
  }
  date = date.slice(0, -1);
  const dateFormat = new Date(date);
  let day = dateFormat.getDate();
  if (day < 10) {
    day = '0' + day;
  }
  const formattedDate = {
    day,
    month: dateFormat.toLocaleString(lang, { month: 'short' }),
    year: dateFormat.getFullYear(),
  };
  return formattedDate;
};

export const addLeadingZero = num => {
  return parseInt(num) < 10 ? '0' + num : num;
}

export const addDays = (days, date = new Date()) => {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

export const dateParseToMilliseconds = (date) => {
  return date && Date.parse(date);
}

export const formatDateTime = (date) => {
  let formattedDate = moment.utc(date).format('YYYYMMDDTHHmmssZ');
  return formattedDate.replace('+00:00', 'Z');
}

export const getDateInFormat = (dateStr, formatStr = 'DD/MM/YYYY') => {
  return dateStr && moment(dateStr).format(formatStr);
}

export const formatUEADateTimeToUTC = (date) => {
  //It is UAE time so defining timeoff with utc
  let formattedDate = moment.utc(`${date}+04:00`).format('YYYYMMDDTHHmmssZ');
  return formattedDate.replace('+00:00', 'Z');
}