import React from 'react';

export const settings = {
  dots: true,
  infinite: true,
  speed: 1000,
  slidesToShow: 1,
  slidesToScroll: 1,
  initialSlide: 0,
  arrows: false,
  lazyLoad: 'progressive',
  pauseOnDotsHover: true,
  autoplay: true,
  autoplaySpeed: 6000,
};
